import { Analytics } from "./analytics.types";

// Tell ts that we can push to dataLayer in window.
declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

// Make sure dataLayer is pushable as to not break the site if GTM script is removed.
if (typeof window !== "undefined") {
  window.dataLayer = window.dataLayer || [];
}

export const analytics: Analytics = {
  ecommerce: (event, ecommerce) => {
    // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clearing_the_ecommerce_object
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event,
      ecommerce,
    });
  },
};
