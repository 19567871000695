import React from "react";
import { Button, ButtonProps } from "@clearabee/ui-library";
import { Redirect, routes } from "lib/routes";
import Link from "next/link";

export type ButtonLinkProps = Omit<ButtonProps, "as" | "onPress" | "ref"> &
  Redirect & {
    children?: React.ReactNode;
    newTab?: boolean;
    onPress?: () => void;
  };

const ButtonLinkComponent = (
  {
    routeName,
    params,
    children,
    newTab = false,
    testID,
    onPress,
    ...rest
  }: ButtonLinkProps & { testID?: string },
  ref: React.ForwardedRef<HTMLAnchorElement | HTMLButtonElement>,
) => {
  const href =
    routeName === "Redirect"
      ? params.url
      : {
          pathname: routes[routeName].path,
          query: params,
        };

  return (
    <Link href={href ?? "#"} passHref legacyBehavior>
      <Button
        {...rest}
        data-testid={testID}
        ref={ref}
        as="a"
        styles={{
          textAlign: "center",
          fontFamily: "Proxima",
          ...(rest.styles as Record<string, unknown>),
        }}
        target={newTab ? "_blank" : "_self"}
        onClick={() => onPress?.()}
      >
        {children}
      </Button>
    </Link>
  );
};

export const ButtonLink = React.forwardRef(ButtonLinkComponent);
