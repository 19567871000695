import { nextClient } from "./nextClient";

export interface ServerDateResponse {
  date: string;
}

export const getServerDate = async (): Promise<ServerDateResponse> => {
  return process.env.NODE_ENV === "development"
    ? { date: new Date().toISOString() }
    : (await nextClient.get<ServerDateResponse>("/server-date")).data;
};
