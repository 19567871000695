import { StyleSheet } from "react-native";
import { theme } from "@clearabee/ui-library-native";

export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing.small,
    width: "100%",
    marginHorizontal: "auto",
  },
});
