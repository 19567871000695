import React, { useState } from "react";
import { createContext } from "react";
import NextLink from "next/link";
import { Header, Footer, LinkWrap, HeaderBasket } from "@clearabee/ui-library";
import { styles } from "./WebLayout.styles";
import { useBasket } from "@clearabee/ui-sdk";
import { useAnalytics } from "lib/hooks/useAnalytics";
import { useAuth } from "lib/hooks/useAuth";
import { DevTools } from "components/DevTools/DevTools";
import { useQuery } from "react-query";
import { getServerDate } from "lib/api/getServerDate";
import { useData } from "lib/hooks/useData";
import { useIsSSR } from "lib/hooks/useIsSSR";
import { handleRemoveItemsFromBasket } from "lib/helpers/basketHelper";

interface WebLayoutProps {
  children: React.ReactNode;
}

export interface WebLayoutContextState {
  setItems: (items: HeaderBasket["items"]) => void;
}

export const WebLayoutContext = createContext({} as WebLayoutContextState);

const LinkComponent: LinkWrap = ({ link, children }) => (
  <NextLink href={link} passHref legacyBehavior>
    {children}
  </NextLink>
);

export const WebLayout = ({ children }: WebLayoutProps): React.ReactElement => {
  const {
    basket,
    update,
    clear,
    isLoading: isBasketUpdating,
    isError: isBasketError,
  } = useBasket();
  const { ecommerce } = useAnalytics();
  const { user } = useAuth();
  const [, setData] = useData();
  useQuery("getServerDate", getServerDate, {
    onSuccess: (data) => setData("serverDate", new Date(data.date)),
    retry: 0,
    refetchOnWindowFocus: "always",
  });
  const isSSR = useIsSSR();
  const [items, setItems] = useState<HeaderBasket["items"]>([]);

  // Pass just the data that we need, along with images for the items
  const headerBasket: HeaderBasket = {
    onRemoveBasketItem: async (itemToRemoveSku) => {
      // uncomment when we allow multi service bookings
      // handleRemoveItemsFromBasket(
      //   itemToRemoveSku,
      //   basket?.items || [],
      //   update,
      //   ecommerce,
      // );
      await update.mutateAsync({ items: [] });
    },
    onClearBasket: () => {
      clear();
      ecommerce.removeFromCart(basket?.items || []);
    },
    totalCost: basket?.totalCost ?? 0,
    items,
    isUpdating: isBasketUpdating,
    isError: isBasketError,
    meta: basket?.meta,
    deliveryAddress: basket?.deliveryAddress,
  };

  /**
   * Don't render anything until client side has chance to access the `window`
   */
  if (isSSR) return <></>;

  return (
    <WebLayoutContext.Provider value={{ setItems }}>
      <div css={styles.container}>
        <Header
          linkWrap={LinkComponent}
          basket={headerBasket}
          isLoggedIn={!!user}
        />
        <div css={styles.main}>{children}</div>
        <div css={styles.topBG} />
        <div style={{ display: "flex", zIndex: 1 }}>
          <Footer linkWrap={LinkComponent} />
        </div>
        {process.env.NODE_ENV === "development" && (
          <div style={styles.devTools}>
            <DevTools />
          </div>
        )}
        <div id="select-portal" className="react-portal" />
      </div>
    </WebLayoutContext.Provider>
  );
};
