import React from "react";
import { View, ViewProps } from "react-native";
import { UserStylesProps, theme } from "@clearabee/ui-library-native";
import { styles } from "./Container.styles";

interface ContainerProps extends UserStylesProps<ViewProps> {
  children: React.ReactNode;
  size?: keyof typeof theme.screens;
}

export const Container = ({
  styles: userStyles,
  size = "xlarge",
  ...rest
}: ContainerProps): React.ReactElement => {
  return (
    <View
      style={[styles.container, { maxWidth: theme.screens[size] }, userStyles]}
      {...rest}
    />
  );
};
