import axios from "axios";
import { Platform } from "react-native";
import { getEnv } from "lib/getEnv";

export const nextClient = axios.create({
  baseURL: `${
    Platform.OS === "web" && typeof window !== "undefined"
      ? window.location.origin
      : getEnv("WEBSITE_URL")
  }/api`,
});
