import { GetRequiredProperties, Split, UnionToIntersection } from "lib/types";
import { Path } from "@clearabee/ui-library-base";
import { CustomTypeOptions } from "react-i18next";
import { PathConfig } from "@react-navigation/native";
import { StackNavigationOptions } from "@react-navigation/stack";
import { JobStatusFilter } from "./utils";

type ParamType = Record<string, string> | undefined;

interface Tab {
  screens: Record<string, Screen<ParamType>>;
  includeBottomNavigation: boolean;
}

// eslint-disable-next-line
interface Screen<Params extends ParamType = undefined, UrlPath = string>
  extends Omit<PathConfig<Record<string, unknown>>, "screens" | "path"> {
  options?: StackNavigationOptions & {
    title: Path<CustomTypeOptions["resources"]["translation"]>;
  };
  path: UrlPath;
}

const createScreen =
  <Params extends ParamType>() =>
  <UrlPath extends string>(screenObject: Screen<Params, UrlPath>) =>
    screenObject;

const Login = createScreen()({
  path: "/login",
});

const Register = createScreen()({
  path: "/register",
});

const PasswordReset = createScreen()({
  path: "/password-reset",
});

const PasswordResetSubmit = createScreen<{ username: string; code?: string }>()(
  {
    path: "/password-reset/[username]",
  },
);

const Dashboard = createScreen()({
  path: "/account",
});

const MyAccount = createScreen()({
  path: "/account/settings",
});

const ProfileDetails = createScreen()({
  path: "/account/settings/profile",
});

const JobHistory = createScreen<{ status?: JobStatusFilter }>()({
  path: "/account/job-history",
});

const ChangePassword = createScreen()({
  path: "/account/settings/password",
});

const DeleteAccount = createScreen()({
  path: "/account/settings/delete-account",
});

const NotificationPreferences = createScreen()({
  path: "/account/notification-preferences",
});

const Job = createScreen<{ ref: string }>()({
  path: "/account/job/[ref]",
});

const Book = createScreen()({
  path: "/book",
});

const ServicePage = createScreen<{
  categorySku: string;
  postcode?: string;
  wasteType?: string;
  products?: string;
  type?: string;
  isPaid?: string;
  ref?: string;
  bagContentsTypes?: string;
  bagContentsNotes?: string;
  distanceFromRoad?: string;
  readyToCollect?: string;
  previousSkip?: string;
}>()({
  path: "/book/[categorySku]",
});

const BulkAdd = createScreen<{
  categorySku: string;
  products: string;
  postcode?: string;
  type?: string;
  isPaid?: string;
  ref?: string;
  bagContentsTypes?: string;
  bagContentsNotes?: string;
  distanceFromRoad?: string;
  readyToCollect?: string;
}>()({
  path: "/book/[categorySku]/bulk-add",
});

const ProductPage = createScreen<{
  categorySku: string;
  productSku: string;
  postcode?: string;
  wasteType?: string;
  exchangeOrderRef?: string;
}>()({
  path: "/book/[categorySku]/[productSku]",
});

const EditBasket = createScreen()({
  path: "/basket/edit",
});

const Checkout = createScreen()({
  path: "/book/checkout",
});

const Basket = createScreen()({
  path: "/basket",
});

const Success = createScreen()({
  path: "/book/success",
});

/*
  For external redirects used by Anchor and ButtonLink components
*/
const Redirect = createScreen<{ url: string }>()({
  path: "",
});

export const routeTabs = {
  DashboardTab: {
    includeBottomNavigation: true,
    screens: {
      Dashboard,
      JobHistory,
      Job,
    },
  },
  AccountTab: {
    includeBottomNavigation: true,
    screens: {
      MyAccount,
      ProfileDetails,
      ChangePassword,
      DeleteAccount,
      NotificationPreferences,
    },
  },
  LoginTab: {
    includeBottomNavigation: false,
    screens: {
      Login,
      Register,
      PasswordReset,
      PasswordResetSubmit,
    },
  },
  BookTab: {
    includeBottomNavigation: true,
    screens: {
      Book,
      ServicePage,
      ProductPage,
      BulkAdd,
      Checkout,
      Success,
      Redirect,
    },
  },
  BasketTab: {
    includeBottomNavigation: true,
    screens: {
      Basket,
      EditBasket,
    },
  },
} as const;

export const routes = Object.values(routeTabs).reduce(
  (routes, route) => ({ ...routes, ...route["screens"] }),
  {} as Routes,
);

/*
  We cannot restrict the routeTabs to a type of Record<string, ...> as this will not produce correct keyof values.
  We also do not want to create a type which is a list of all screens and then do Record<LIST_OF_SCREENS, ...>
  as this will require maintaining two lists. The below solution checks if our routes object is
  indeed a Record of type Screen. In case it's not - throw a TS error.
*/
type RouteTabs<RouteTabs extends Record<string, Tab> = typeof routeTabs> =
  RouteTabs;
export type Routes = UnionToIntersection<RouteTabs[keyof RouteTabs]["screens"]>;

/*
  Extract the params object type from Screen<PARAMS> of a screen

  Example:

  interface CustomParams {
    categorySku: string;
    productSku: string;
  }

  GetParams<Screen<CustomParams>> === CustomParams = true
  GetParams<Screen<undefined>>    === undefined    = true
  GetParams<Screen>               === undefined    = true
*/
type GetParams<Type> = Type extends Screen<infer Params> ? Params : never;
type GetQuery<
  Path extends string,
  Param = Split<Path, "/">[number],
> = Param extends `[${infer Param}]` ? Param : never;

export type GetRouteParams<Route extends keyof Routes> = GetParams<
  Routes[Route]
>;
export type GetRouteQuery<Route extends keyof Routes> = Omit<
  GetParams<Routes[Route]>,
  GetQuery<Routes[Route]["path"]>
>;

export type RouteNames = keyof Routes;
export type RouteParams = {
  [Route in RouteNames]: GetParams<Routes[Route]>;
};

/*
  To be used in functions responsible for screen/page redirects.
  Allows for type-safe passing of params between screens on Next and Native
  making params a required property matching the object type from Screen<...>.
  
  For examples go to - useRouter.ts, Anchor.tsx, ButtonLink.tsx
*/

export type Redirect = {
  [RouteName in RouteNames]: {
    routeName: RouteName;
  } & (RouteParams[RouteName] extends undefined
    ? { params?: undefined }
    : GetRequiredProperties<RouteParams[RouteName]> extends never
    ? { params?: RouteParams[RouteName] }
    : { params: RouteParams[RouteName] });
}[RouteNames];

export const getTabFromRoute = (
  routeName: string,
): keyof RouteTabs | undefined => {
  const tabs = Object.keys(routeTabs) as (keyof typeof routeTabs)[];

  return tabs.find((tab) =>
    Object.keys(routeTabs[tab].screens).find((screen) => screen === routeName),
  );
};
