import { ApiResponseData, instance } from "@clearabee/ui-sdk";
import { analytics } from "lib/analytics";
import { Nullable } from "lib/types";
import { capitalise } from "lib/utils";
import { Platform } from "react-native";

type Item = Nullable<
  Pick<
    ApiResponseData<typeof instance.catalogues.getBasket>["items"][number],
    "sku" | "title" | "parentSku" | "price" | "qty"
  >
>;

type SingleItem = Omit<Item, "qty">;

export interface UseAnalyticsReturn {
  ecommerce: {
    viewItemList: (
      items: SingleItem[],
      item_list_name: string,
      item_list_id: string,
    ) => void;
    viewItem: (
      item: SingleItem,
      item_list_name?: string,
      item_list_id?: string,
    ) => void;
    selectItem: (
      item: SingleItem,
      item_list_name?: string,
      item_list_id?: string,
    ) => void;
    addToCart: (
      items: Item[],
      item_list_name?: string,
      item_list_id?: string,
    ) => void;
    removeFromCart: (
      items: Item[],
      item_list_name?: string,
      item_list_id?: string,
    ) => void;
    viewCart: (
      items: Item[],
      value: number,
      item_list_name?: string,
      item_list_id?: string,
    ) => void;
    beginCheckout: (items: Item[]) => void;
    purchase: (items: Item[], value: number, orderRef: string) => void;
  };
}

const affiliation = `Home Waste App - ${capitalise(Platform.OS)}`;

export const useAnalytics = (): UseAnalyticsReturn => {
  const ecommerce = {
    // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_item_list
    viewItemList: (
      items: SingleItem[],
      item_list_name: string,
      item_list_id: string,
    ) => {
      analytics.ecommerce("view_item_list", {
        items: items?.map((item, index) => ({
          index,
          item_id: item.sku,
          item_name: item.title,
          item_category: item.parentSku,
          item_list_name,
          item_list_id,
          price: item.price,
          currency: "GBP",
        })),
      });
    },
    // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_item
    viewItem: (
      item: SingleItem,
      item_list_name?: string,
      item_list_id?: string,
    ) => {
      analytics.ecommerce("view_item", {
        items: [
          {
            index: 0,
            item_id: item.sku,
            item_name: item.title,
            item_category: item.parentSku,
            item_list_name,
            item_list_id,
            price: item.price,
            currency: "GBP",
          },
        ],
      });
    },
    // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#select_item
    selectItem: (
      item: SingleItem,
      item_list_name?: string,
      item_list_id?: string,
    ) => {
      analytics.ecommerce("select_item", {
        items: [
          {
            index: 0,
            item_id: item.sku,
            item_name: item.title,
            item_category: item.parentSku,
            item_list_name,
            item_list_id,
            price: item.price,
            currency: "GBP",
          },
        ],
      });
    },
    // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_to_cart
    addToCart: (
      items: Item[],
      item_list_name?: string,
      item_list_id?: string,
    ) => {
      analytics.ecommerce("add_to_cart", {
        items: items?.map((item, index) => ({
          index,
          item_id: item.sku,
          item_name: item.title,
          item_category: item.parentSku,
          item_list_name,
          item_list_id,
          price: item.price,
          currency: "GBP",
          quantity: items[index].qty,
        })),
      });
    },
    // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#remove_from_cart
    removeFromCart: (
      items: Item[],
      item_list_name?: string,
      item_list_id?: string,
    ) => {
      analytics.ecommerce("remove_from_cart", {
        items: items?.map((item, index) => ({
          index,
          item_id: item.sku,
          item_name: item.title,
          item_category: item.parentSku,
          item_list_name,
          item_list_id,
          price: item.price,
          currency: "GBP",
          quantity: items[index].qty,
        })),
      });
    },
    // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_cart
    viewCart: (
      items: Item[],
      value: number,
      item_list_name?: string,
      item_list_id?: string,
    ) => {
      analytics.ecommerce("view_cart", {
        value,
        currency: "GBP",
        items: items?.map((item, index) => ({
          index,
          item_id: item.sku,
          affiliation,
          item_name: item.title,
          item_category: item.parentSku,
          item_list_name,
          item_list_id,
          price: item.price,
          currency: "GBP",
          quantity: items[index].qty,
        })),
      });
    },
    // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#begin_checkout
    beginCheckout: (items: Item[]) => {
      analytics.ecommerce("begin_checkout", {
        items: items?.map((item, index) => ({
          index,
          item_id: item.sku,
          item_name: item.title,
          item_category: item.parentSku,
          price: item.price,
          currency: "GBP",
          quantity: items[index].qty,
        })),
      });
    },
    // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#purchase
    purchase: (items: Item[], value: number, orderRef: string) => {
      analytics.ecommerce("purchase", {
        transaction_id: orderRef,
        affiliation,
        value,
        currency: "GBP",
        items: items?.map((item, index) => ({
          index,
          item_id: item.sku,
          item_name: item.title,
          item_category: item.parentSku,
          price: item.price,
          currency: "GBP",
          quantity: items[index].qty,
        })),
      });

      // Extra tracking for Microsoft Ads
      window.uetq = window.uetq || [];
      window.uetq.push("event", "Purchase", {
        event_category: "Purchase",
        event_label: "Checkout",
        transaction_id: orderRef,
        ecomm_totalvalue: value,
        revenue_value: value,
        currency: "GBP",
        gv: value,
        gc: "GBP",
        items: items?.map((item, index) => ({
          id: item.sku,
          price: item.price,
          quantity: items[index].qty,
        })),
      });
    },
  };

  return { ecommerce };
};
