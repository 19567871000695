import React from "react";
import { WebLayout } from "containers/WebLayout/WebLayout";
import { ErrorBoundary } from "components/ErrorBoundary/ErrorBoundary";
import { captureException } from "@sentry/nextjs";
import { theme } from "@clearabee/ui-library";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

export const withWebLayout = <T extends EmotionJSX.IntrinsicAttributes>(
  Page: React.ComponentType<T>,
): React.ComponentType<T> => {
  return (props) => (
    <WebLayout>
      <ErrorBoundary
        styles={{
          marginTop: theme.spacing.large,
          marginBottom: theme.spacing.large,
        }}
        onError={(err) => {
          captureException(err, {
            tags: {
              errorBoundary: true,
              caughtAt: "_app.tsx",
            },
          });
        }}
      >
        <Page {...(props as T)} />
      </ErrorBoundary>
    </WebLayout>
  );
};
