import React from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import {
  ContextBasketCreate,
  createOrUpdateBasket,
  useBasket,
} from "@clearabee/ui-sdk";
import { Button } from "components/Core/Button/Button";
import { Text, useModal } from "@clearabee/ui-library-native";
import dayjs from "dayjs";

export const DevTools = (): React.ReactElement => {
  const { create, update, basket, reset } = useBasket();
  const [Modal, showModal] = useModal();

  const handleAddDummyData = async () => {
    const body: ContextBasketCreate = {
      contact: {
        firstName: "Developer",
        lastName: "Developer",
        email: "develop@clearabee.co.uk",
        phoneNumber: "0123456789",
      },
      deliveryAddress: {
        line1: "Clearabee Ltd",
        line2: "Griffin House",
        city: "Birmingham",
        county: "West Midlands",
        postcode: "B3 1DW",
      },
      description: "",
      date: dayjs().add(3, "weeks").set("day", 3).toISOString(), // set to 3 weeks from now on a wednesday
      items: [
        {
          sku: "FLEXLOAD",
          qty: 1,
          price: 30,
        },
      ],
    };

    await createOrUpdateBasket(body, { create, update, basket });
    showModal(false);
  };

  const handleResetBasket = () => {
    reset();
    showModal(false);
  };

  return (
    <>
      <View style={{ alignSelf: "flex-start" }}>
        {Platform.OS === "web" && (
          <Button
            onClick={() => showModal(true)}
            color="greyscale"
            size="medium"
          >
            Dev Tools
          </Button>
        )}
        {Platform.OS !== "web" && (
          <TouchableOpacity
            onPress={() => showModal(true)}
            style={{ flexDirection: "row" }}
          >
            <Text color="light">Dev Tools</Text>
          </TouchableOpacity>
        )}
      </View>
      <Modal
        heading="Dev Tools"
        onClose={() => showModal(false)}
        actions={[
          <Button onClick={handleAddDummyData} color="greyscale" size="medium">
            Add dummy product
          </Button>,
          <Button onClick={handleResetBasket} color="greyscale" size="medium">
            Force reset basket
          </Button>,
        ]}
      />
    </>
  );
};
