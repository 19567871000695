import {
  StyleSheet,
  theme,
  createResponsiveStyles,
} from "@clearabee/ui-library";
import smallBackground from "lib/images/svgs/Horizon.svg";
import bgGreen from "lib/images/svgs/Hills.svg";
import bgGreenUltrawide from "lib/images/svgs/HillsUltrawide.svg";

export const styles = StyleSheet({
  topBG: createResponsiveStyles({
    base: {
      display: "none",
      zIndex: 2,
    },
    medium: {
      position: "absolute",
      top: 0,
      left: `-${theme.spacing.xlarge}`,
      display: "block",
      background: `url(${bgGreen}) no-repeat top left`,
      backgroundSize: "contain",
      height: 120,
      width: theme.screens.small,
    },
    large: {
      width: theme.screens.medium,
      top: `-${theme.spacing.small}`,
    },
    xlarge: {
      top: `-${theme.spacing.small}`,
      width: theme.screens.large,
    },
    xlarge2: {
      width: theme.screens.xlarge,
      left: `-${theme.spacing.xlarge}`,
    },
    xlarge3: {
      background: `url(${bgGreenUltrawide}) no-repeat`,
      backgroundSize: "auto 100%",
      left: "50%",
      transform: "translate(-70%)",
    },
  }),
  devTools: {
    position: "fixed",
    bottom: 0,
    right: 0,
    margin: theme.spacing.large,
    zIndex: theme.stack.top,
  },
  container: createResponsiveStyles({
    base: {
      fontFamily: "Proxima",
      position: "relative",
      top: -2,
      backgroundColor: theme.colors.brand.base,
      alignContent: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      minHeight: "100vh",
      background: `url(${smallBackground}) no-repeat top center #733DC6`,
      backgroundSize: "cover",
    },
    large: {
      backgroundSize: "auto",
      backgroundPosition: "top 1px left -150px",
    },
    xlarge4: {
      backgroundPosition: "top right",
    },
  }),
  main: createResponsiveStyles({
    base: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      flex: 1,
    },
    xlarge: {
      marginTop: theme.spacing.medium,
    },
  }),
  loader: {
    flex: 1,
    width: "100%",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
